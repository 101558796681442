import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Button } from "../components/Core";
import { graphql } from "gatsby";
import PostCard from "../components/PostCard";

const Services = ({ data }) => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="6">
                <Title variant="hero">Services</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative" py={4}>
          <Container>
            <Row className="justify-content-center">
              {data.page.frontmatter.services.map((service, serviceIndex) => (
                <Col
                  lg="4"
                  className="mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={serviceIndex * 100 + 200}
                  data-aos-duration="800"
                  data-aos-once="true"
                >
                  <PostCard image={service.image} title={service.title}>
                    <ul>
                      {service.dotPoints.map((listItem, dotPointIndex) => (
                        <li
                          data-aos="fade-up"
                          data-aos-delay={dotPointIndex * 100 + 300}
                          data-aos-duration="800"
                          data-aos-once="true"
                          data-aos-offset="-200"
                        >
                          {listItem}
                        </li>
                      ))}
                    </ul>
                  </PostCard>
                </Col>
              ))}
            </Row>
          </Container>
        </Section>
        <Row className="justify-content-center text-center mb-5">
          <Button to="/contact">Contact Me</Button>
        </Row>
      </PageWrapper>
    </>
  );
};
export default Services;

export const query = graphql`
  query {
    page: mdx(frontmatter: { slug: { eq: "services" } }) {
      body
      frontmatter {
        services {
          dotPoints
          title
          imageAlt
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 728
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;
